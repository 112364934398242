<template>
  <Portlet
    :title="'Video'"
    icon="video"
    class="videoMediaReview"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-if="videoRuns.length > 0">
      <div
        v-for="(item, index) in videoRuns"
        :key="`videoRun-${ index }`"
      >
        <h4 :class="index != 0 ? 'mt-3' : ''">
          <router-link
            :to="`/installation/${ item.installationId }`"
          >
            Spot {{ item.spotId }} - {{ item.spotName }}
          </router-link>
        </h4>
        <div class="row">
          <MediaReviewVideoSessionOverview
            :run="item.firstItem"
            :index="index"
            :is-playing="playingArr[index].playing"
          />
          <MediaReviewVideoSessionOverview
            :run="item.secondItem"
            :index="index"
            :is-playing="playingArr[index].playing"
          />
          <MediaReviewVideoSessionOverview
            :run="item.thirdItem"
            :index="index"
            :is-playing="playingArr[index].playing"
          />
          <MediaReviewActions
            :run="item"
            :is-video="true"
            :index="index"
            @isPlaying="playVideo"
          />
        </div>
      </div>
    </template>
    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>    
  </Portlet>
</template>

<script>
export default {
  name: "VideoMediaReview",
  components: {
    MediaReviewVideoSessionOverview: () => import("@/components/MediaReview/MediaReviewVideoSessionOverview.vue"),
    MediaReviewActions: () => import("@/components/MediaReview/MediaReviewActions.vue")
  },
  metaInfo () {
    return {
      title: "Video"
    };
  },
  data () {
    return {
      loading: false,
      videoRuns: [],
      playingArr: null,
      jobInterval: null
    };
  },
  created () {
    this.getVideoRuns();
  },
  beforeDestroy () {
    this.clearInterval();
  },
  methods: {
    createInterval () {
      if (this.jobInterval != null) {
        this.clearInterval();
      }
      this.loading = true;
      this.jobInterval = window.setInterval(() => {
        this.getVideoRunsJob();
      }, 1000);
    },
    clearInterval () {
      window.clearInterval(this.jobInterval);
      this.jobInterval = null;
      this.loading = false;
    },
    playVideo (index, playing) {
      this.playingArr[index].playing = playing;
    },
    //#region API-calls
    getVideoRuns () {
      this.axios
        .get(`/Media/GetVideoMediaReview`)
        .finally(() => {
          this.createInterval();
        });
    },
    getVideoRunsJob () {
      this.axios
        .get(`/Media/GetVideoMediaReviewJob`)
        .then(response => {
          if (response.data && response.data.done) {
            this.clearInterval();
          }
          if (response.data && response.data.mediaReviewItems) {
            this.videoRuns = response.data.mediaReviewItems;
            this.playingArr = [];
            for(var i = 0; i < this.videoRuns.length; i++) {
              this.playingArr.push({index: i, playing:false});
            }
          }
        })
    }
  }
};
</script> 